import findIndex from 'lodash/findIndex'
import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import { UploadFile } from 'antd/lib/upload/interface'
import { UpdatePolicy } from '@features/Settings/Imports/constants'

type UploadType = {
    batchName?: string
    file?: UploadFile
    isCompanyId?: boolean
    isTradeId?: boolean
}

export interface IdataImportFlowState {
    filesCount: any[]
    objectsCount: any[]
    objects: any[]
    upload: UploadType
    guest: UploadType
    host: UploadType
    itp: UploadType
    category: UploadType
    excelMap: any[]
    publishStatus?: string
    publishData: boolean
    updatePolicy: UpdatePolicy.KEEP_ORIGINAL | UpdatePolicy.MERGE | UpdatePolicy.OVERWRITE
    finalizeData: any
    importTimeModal: {
        state: boolean
    }
    createObjectModal: {
        state: boolean
        groupName: string
        value: string
        columnHeaderName: string
    }
}

const initialState: IdataImportFlowState = {
    filesCount: [],
    objectsCount: [],
    objects: [],
    importTimeModal: {
        state: false
    },
    createObjectModal: {
        state: false,
        groupName: '',
        value: '',
        columnHeaderName: ''
    },
    guest: {
        batchName: '',
        file: null,
        isCompanyId: false,
        isTradeId: false
    },
    host: {
        batchName: '',
        file: null,
        isCompanyId: false,
        isTradeId: false
    },
    itp: {
        batchName: '',
        file: null,
        isCompanyId: false,
        isTradeId: false
    },
    category: {
        batchName: '',
        file: null,
        isCompanyId: false,
        isTradeId: false
    },
    upload: {
        batchName: '',
        file: null,
        isCompanyId: false,
        isTradeId: false
    },
    excelMap: [],
    publishData: false,
    publishStatus: '',
    finalizeData: {},
    updatePolicy: UpdatePolicy.MERGE
}

const dataImportFlow = createSlice({
    name: 'hub/data-import/flow',
    initialState,
    reducers: {
        setFilesCount(state, action) {
            state.filesCount = action.payload
        },
        setPublishData(state, action) {
            state.publishData = action.payload
        },
        setPublishStatus(state, action) {
            state.publishStatus = action.payload
        },
        setUpdatePolicy(state, action) {
            state.updatePolicy = action.payload
        },
        toggleImportTimeModal(state) {
            state.importTimeModal.state = !state.importTimeModal.state
        },
        setObjectCount(state, action) {
            state.objectsCount = action.payload
        },
        selectObjects(state, action) {
            state.objects = action.payload
        },
        // Object Modal Start
        changeCreateObjectModal(state, action) {
            const { createObjectModal } = state
            createObjectModal.state = action.payload?.state || createObjectModal.state
            createObjectModal.groupName = action.payload?.groupName || createObjectModal.groupName
            createObjectModal.value = action.payload?.value || createObjectModal.value
            createObjectModal.columnHeaderName = action.payload?.columnHeaderName || createObjectModal.columnHeaderName
        },
        resetCreateObjectModal(state) {
            state.createObjectModal = initialState.createObjectModal
        },
        // Object Modal End
        setUploadData(state, action) {
            const { payload } = action
            const objectKeys = Object.entries(payload.data || payload)
            const acceptableKeys = ['batchName', 'file', 'isCompanyId', 'isTradeId']

            for (const [key, value] of objectKeys) {
                if (acceptableKeys.indexOf(key) >= 0) state[payload.type || 'upload'][key] = value
            }
        },
        setFinalData(state, action) {
            const { payload } = action
            const index = findIndex(state.excelMap, (item: any) => item.columnHeaderName === payload.columnHeaderName)
            if (index > -1 && payload.value === 0) {
                state.excelMap = state.excelMap.filter((_, i) => i !== index)
            } else {
                if (payload.value !== 0) {
                    if (index > -1) {
                        state.excelMap[index] = payload
                    } else {
                        state.excelMap = [...state.excelMap, payload]
                    }
                }
            }

            state.finalizeData = makeFinalizeExcelMap([...state.excelMap])
        },
        setDefaultData(state, action) {
            const { payload } = action
            state.excelMap = payload

            state.finalizeData = makeFinalizeExcelMap([...state.excelMap])
        },
        setFinalizeData(state, action) {
            const { payload } = action
            state.finalizeData = payload
        },
        reset: () => initialState,
        extraReducers: (builder: any) => {
            builder.addCase(PURGE, state => {
                state = initialState
            })
        }
    }
})

const makeFinalizeExcelMap = excelMap => {
    return excelMap.reduce((acc, cur) => {
        return {
            ...acc,
            [cur.groupName]: {
                ...excelMap
                    .filter(item => item.groupName === cur.groupName)
                    .reduce(
                        (acc2, cur2) => ({
                            ...acc2,
                            [cur2.value]: cur2.columnHeaderName
                        }),
                        {}
                    )
            }
        }
    }, {})
}

export const dataImportFlowActions = dataImportFlow.actions
export default dataImportFlow.reducer
